<template>
    <div class="container Nunito" style="max-width: 100%">
      <section class="section headerSection" style="padding-bottom: 1rem;">
        <div class="container has-text-centered">
          <h2 class="title is-size-3" >Privacy Policy </h2>
          <p class="subtitle is-size-4 has-text-white" style="margin-bottom: 0px;">Created, August 13th 2020.</p>
          <p class="subtitle is-size-4 has-text-white">Last Updated, April 11th 2021.</p>
        </div>
      </section>
      <section class="section" style="padding-top: 1.5rem;">
        <div class="container">
          <h1 class="title is-size-3 has-text-white" style="margin-bottom: 1.5rem;">General Information.</h1>
          <p class="subtitle is-size-6 has-text-white" style="padding-left: 30px; margin-bottom: 10px;">Grace Bot (Dreaming Dev) is commited to keep your information secure so it may not be abused or be given out to some other 3rd party services except if specified.</p>
          <p class="is-size-6 has-text-white" style="padding-left: 30px; margin-bottom: 1.5rem;">Any information we may gather is to be utilized over our sites and other services we own, for example, Discord bots. Proceeding to utilize these services will imply that you acknowledge our privacy policy and terms of use to utilize your information.</p>

          <h1 class="title is-size-3 has-text-white" style="margin-bottom: 1.5rem;">What do we store about you?</h1>
          <p class="subtitle is-size-6 has-text-white" style="padding-left: 30px; margin-bottom: 10px;">The majority of the information we store is essential to the services we provide with the goal that they can work appropriately, This includes Discords information (or "data"), for example, your user id, name, discrim and avatar (or "profile image") on the off chance that you are signed into any of our sites or public information area.</p>
          <p class="subtitle is-size-6 has-text-white" style="padding-left: 30px; margin-bottom: 10px;">We will keep your information forever so we can offer a superior service and to likewise verify you on any of our service with other information, for example, client or server ("guild") settings.</p>
          <p class="subtitle is-size-6 has-text-white" style="padding-left: 30px; margin-bottom: 10px; ">Grace will temporarily or permanently store and log Discord data (or "information") like messages, nicknames/usernames, images, and files for enabled ("guilds") history loggers, and research/improvement data.</p>
          <p class="is-size-6 has-text-white" style="padding-left: 30px; margin-bottom: 1.5rem;">Some sensitive information might be kept in the event that you have submitted them on our site or services, for example, application pages with emails<span style="">, message archives</span> or whatever other application where we require data.</p>

          <h1 class="title is-size-3 has-text-white" style="margin-bottom: 1.5rem;">What do we with your data?</h1>
          <p class="subtitle is-size-6 has-text-white" style="padding-left: 30px; margin-bottom: 10px;">We will use your Discord id, name and discrim across many of our services including public information areas that we have made and will use your data to display information, stats or images from various APIs whenever you request them to be used.</p>
           <p class="is-size-6 has-text-white" style="padding-left: 30px; margin-bottom: 1.5rem;">Any features you use in our bot may also use personal information that you enter in the bot such as account connections or logging and will only be enabled if you request it or if a server admin enables it.</p>

          <h1 class="title is-size-3 has-text-white" style="margin-bottom: 1.5rem;">Website area.</h1>
          <p class="subtitle is-size-6 has-text-white" style="padding-left: 30px; margin-bottom: 10px;">We use Discord oauth to authenticate users on our websites/services, This will include your user id, name, discrim that will be stored in our database that can be used in our other services.</p>
           <p class="is-size-6 has-text-white" style="padding-left: 30px; margin-bottom: 1.5rem;">Your session/authentication data will be stored as a cookie/session on your computer and will expire after an undisclosed amount of time OR if you logout.</p>

          <h1 class="title is-size-3 has-text-white" style="margin-bottom: 1.5rem;">Discord data</h1>
          <p class="subtitle is-size-6 has-text-white" style="padding-left: 30px; margin-bottom: 10px;">Our Discord bots, and other services use the <router-link tag="a" :to="{ path: '/redirect?to=https://discord.com/' }">Discord API</router-link> to obtain data and if you use them we may store your user id, name and discrim to be used in the bot.</p>
          <p class="subtitle is-size-6 has-text-white" style="padding-left: 30px; margin-bottom: 10px;">Any user or server setting that you configure in the bot or other services will also be stored which includes profile descriptions, welcome messages or urls.</p>
           <p class="is-size-6 has-text-white" style="padding-left: 30px; margin-bottom: 1.5rem;">You can view the <router-link tag="a" :to="{ path: '/redirect?to=https://discord.com/privacy' }">Discord Privacy Policy</router-link> for more info & to say up to date with your rights using discord.</p>

          <h1 class="title is-size-3 has-text-white" style="margin-bottom: 1.5rem;">Other 3rd party data/services, Avertising services/Cookies.</h1>
          <p class="subtitle is-size-6 has-text-white" style="padding-left: 30px; margin-bottom: 10px;">We may/will use 3rd party services such as <router-link tag="a" :to="{ path: '/redirect?to=https://www.patreon.com/home' }">Patreon</router-link> or <router-link tag="a" :to="{ path: '/redirect?to=https://paypal.com' }">PayPal</router-link> for donations but we do not store any personal information such as names or emails, They will only be viewed and used on the services themselfs. The maxum we store is your Patreon ID to register your donations.</p>
          <p class="subtitle is-size-6 has-text-white" style="padding-left: 30px; margin-bottom: 10px;">We use cookies and other technologies to keep our site working at all times on your computer. Like using it for known what your logged in to, notifactions. You can disable these at any time by going into your browser settings. Some features may not work.</p>
          <p class="subtitle is-size-6 has-text-white" style="padding-left: 30px; margin-bottom: 10px;">Avertising services, which includes <router-link tag="a" :to="{ path: '/redirect?to=https://policies.google.com' }">Google (Adsense/Analytics)</router-link>, <router-link tag="a" :to="{ path: '/redirect?to=https://twitter.com/en/privacy' }">Twitter</router-link>, and <router-link tag="a" :to="{ path: '/redirect?to=https://www.facebook.com/about/privacy' }">FaceBook</router-link> (and other services that may be included in our services) may/could collect information for optimizing systems managed by outside services.</p>
          <p class="subtitle is-size-6 has-text-white" style="padding-left: 30px; margin-bottom: 10px;">If you do not wish to receive personalized Avertising services or other 3rd party services. you may be eligible to choose to opt-out of these 3rd party services.</p>
          <p class="is-size-6 has-text-white" style="padding-left: 30px; margin-bottom: 1.5rem;">You can view the Privacy Policy for the 3rd party services we use. <router-link tag="a" :to="{ path: '/redirect?to=https://privacy.patreon.com/policies' }">Patreon Policy</router-link>, <router-link tag="a" :to="{ path: '/redirect?to=https://paypal.com/us/webapps/mpp/ua/privacy-full' }">PayPal Policy</router-link>, <router-link tag="a" :to="{ path: '/redirect?to=https://policies.google.com' }">Google Policy</router-link>, <router-link tag="a" :to="{ path: '/redirect?to=https://twitter.com/en/privacy' }">Twitter Policy</router-link>, <router-link tag="a" :to="{ path: '/redirect?to=https://www.redditinc.com/policies/privacy-policy' }">Reddit Policy</router-link>, and <router-link tag="a" :to="{ path: '/redirect?to=https://www.facebook.com/about/privacy' }">FaceBook Policy</router-link> to stay up to date with your rights.</p>

          <h1 class="title is-size-3 has-text-white" style="margin-bottom: 1.5rem;">Is my data secure?</h1>
          <p class="subtitle is-size-6 has-text-white" style="padding-left: 30px; margin-bottom: 10px;">Your data is secure and protected on a private server that is managed by us, We also use unique passwords and other forms of security such as 2fa or randomly changing passwords to ensure that no data is leaked or can be abused using admin access.</p>
          <p class="is-size-6 has-text-white" style="padding-left: 30px; margin-bottom: 1.5rem; font-weight: bold;">We do not use any free services, to prevent unauthorizsed access and tampering. All of the services we use are paid and are singed on paper/documents.</p>

          <h1 class="title is-size-3 has-text-white" style="margin-bottom: 1.5rem;">Request data removal Or data download.</h1>
          <p class="subtitle is-size-6 has-text-white" style="padding-left: 30px; margin-bottom: 10px;">You can request your data to be removed or collected at any time but this could be affected if you are a donator or banned.</p>
          <p class="subtitle is-size-6 has-text-white" style="padding-left: 30px; margin-bottom: 10px;">If you request to download your user data please be logged in and press <a @click="$store.dispatch('userDataDump')">User Data Dump</a> inside your dropdown area while logged in.</p>
          <p class="subtitle is-size-6 has-text-white" style="padding-left: 30px; margin-bottom: 10px;">If you request to download your guild data please be logged in and inside your guild dashboard under the main area press <router-link tag="a" :to="{ path: '/dashboard' }">Download Data Dump</router-link> to download your data.</p>
          <p class="subtitle is-size-6 has-text-white" style="padding-left: 30px; margin-bottom: 10px;">The recommended way to contact us for data removal is by our <router-link tag="a" :to="{ path: '/support' }">Support Server</router-link> or you can contact a staff member personally.</p>
          <p class="is-size-6 has-text-white" style="padding-left: 30px; margin-bottom: 1.5rem;">If you have lost your account we may request additional info or verification to remove your data or if it has been deleted by Discord.</p>

          <h1 class="title is-size-3 has-text-white" style="margin-bottom: 1.5rem;">Policy updates/changes.</h1>
          <p class="subtitle is-size-6 has-text-white" style="padding-left: 30px; margin-bottom: 2.5rem;">We may update our privacy policy at any time to correct any issues or support other services we have developed and will update this page with a log including announcements on our Discord server to notify you of any changes.</p>

          <h1 class="title is-size-3 has-text-white" style="margin-bottom: 1.5rem;">Contact Us</h1>
          <p class="subtitle is-size-6 has-text-white" style="padding-left: 30px; margin-bottom: 15px;">If you have any issues with our services or want to provide feedback you can join our <router-link tag="a" :to="{ path: '/support' }">Discord server</router-link> or email us <a href="mailto:support@gracebot.net">support@gracebot.net</a>.</p>
          <p class="is-size-6 has-text-white" style="padding-left: 30px; margin-bottom: 0px;">08/20/2020 - Text/Format change for new website.</p>
          <p class="is-size-6 has-text-white" style="padding-left: 30px; margin-bottom: 0px;">08/13/2020 - First public policy.</p>
          <p class="is-size-6 has-text-white" style="padding-left: 30px; margin-bottom: 0px;">09/16/2020 - Avertising services/Cookies Update.</p>
          <p class="is-size-6 has-text-white" style="padding-left: 30px; margin-bottom: 1.5rem;">4/11/2021 - Archive Privacy Update.</p>

        </div>
      </section>
    </div>
</template>
<script>
  export default {
    name: '404',
    components: {}
  }
</script>

<style scoped>
.tabled {
  display: table;
  height: 35rem;
  margin: 0 auto;
}
.tabled-cell {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
a:hover {
  color: lightgray;
}
</style>
